<ng-container *ngIf="client$ | async as client">
  <radio-group [formControl]="control">
    <ng-container *ngIf="visitDates?.length">
      <div class="select-date-options-container">
        <div class="radio-container">
          <ng-container *ngFor="let date of visitDates; trackBy: trackByDates">
            <div class="radio-option">
              <ng-container *ngTemplateOutlet="datesTemplate; context: { date: date }"></ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-template #datesTemplate let-date="date">
      <div class="radio-option-info">
        <app-radio [value]="date">
          {{date.visitDate | weekdayDayMonthParser}}
        </app-radio>
      </div>
    
      <div 
        *ngIf="
          date.offRoute && 
          date.quantityMinFrozen &&
          showMinAmountByDates
        "
        class="radio-option-min-amount"
        [ngClass]="{'green-text': showGreenTextMinAmount}">
        <svg 
          *ngIf="showGreenTextMinAmount"
          class="icon">
          <use xlink:href="./assets/icons/tabler-sprite.svg#tabler-truck" />
        </svg>
        {{ date.quantityMinFrozen | boxes }}
      </div>
      
      <div 
        *ngIf="
          !date.offRoute && 
          date.amountMinFrozen &&
          showMinAmountByDates
        "
        class="radio-option-min-amount">
        {{
          'NEW_ORDER.DELIVERY_PLAN.MINIMUM_AMOUNT' | translate: {
            amount: date.amountMinFrozen | currency
          } 
        }}
      </div>
    </ng-template>
  </radio-group>
</ng-container>
