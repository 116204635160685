import { Injectable } from '@angular/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';

import { VisitDate } from '../models/client.model';
import { getCartVisitDate } from '../state/reducers/cart.reducer';
import { getClientVisitDates } from '../state/reducers/client.reducer';
import { DateUtility } from '../utils/app-date-utils';
import { loadDeliveryDates, updateDeliveryDate } from '../state/actions/cart.actions';

import { SMART_DELIVERY } from './visit-dates/constants/visit-dates.constants';
import { VisitType } from './visit-dates/enum/visit-dates.enum';

@Injectable({
  providedIn: 'root'
})
export class VisitDatesService {
  visitDates: VisitDate[];
  selectedVisitDate: string | Date;

  get clientCartStream$(): Observable<[string | Date, VisitDate[]]> {
    return combineLatest([
      this.store.select(getCartVisitDate),
      this.store.select(getClientVisitDates),
    ]);
  }

  constructor(private store: Store) { }

  getVisitDatesByClient(): Subscription {
    return this.clientCartStream$.subscribe({
      next: ([
        cartVisitDate,
        clientVisitDates,
      ]) => {
        this.visitDates = clientVisitDates;
        this.selectedVisitDate = this.getCurrentVisitDate(cartVisitDate);
      },
    })
  }

  private getCurrentVisitDate(cartVisitDate: string | Date): string | Date {
    if (!cartVisitDate) {
      return this.visitDates[0]?.visitDate;
    }

    const isCartCurrentDateValid = this.visitDates.some(visitDateObj =>
      DateUtility.isSameDay(visitDateObj.visitDate, cartVisitDate)
    );

    if (this.visitDates.length !== 0) {
      return isCartCurrentDateValid
        ? cartVisitDate
        : this.visitDates[0].visitDate;
    }
  }

  getSelectedVisitDate(): VisitDate {
    return this.visitDates.filter((date) => {
      return DateUtility.isSameDay(date.visitDate, this.selectedVisitDate)
    })[0];
  }

  getFirstOnOffVisitDate(): Date {
    const ambientOnOffVisitDates = [
      ...this.getOnRouteVisitDates(),
      ...this.getOffRouteVisitDates(),
    ].sort((a, b) => new Date(a.visitDate).getTime() - new Date(b.visitDate).getTime());
    
    return new Date(ambientOnOffVisitDates[0]?.visitDate);
  }

  getOnRouteVisitDates(): VisitDate[] {
    return this.visitDates.filter((date) => {
      return !date.offRoute && date.visitType === VisitType.NO_FROZEN && !date.source;
    });
  }

  getOffRouteVisitDates(): VisitDate[] {
    return this.visitDates.filter((date) => {
      return date.offRoute && date.visitType === VisitType.NO_FROZEN && !date.source;;
    });
  }

  getSmartDeliveryVisitDates(): VisitDate[] {
    return this.visitDates.filter((date) => {
      return date.source === SMART_DELIVERY;
    });
  }

  getSmartDeliveryVisitDatesLessThanFirstVisitDate(): VisitDate[] {
    const firstOnOffVisitDate = this.getFirstOnOffVisitDate();
    
    if (isNaN(firstOnOffVisitDate.getTime())) {
      return this.getSmartDeliveryVisitDates();
    }
    
    return this.visitDates
      .filter((date) => date?.source === SMART_DELIVERY)
      .filter((date) => new Date(date.visitDate) < firstOnOffVisitDate);
  }

  getSmartDeliveryDatesGreaterThanFirstVisitDate(): VisitDate[] {
    const firstOnOffVisitDate = this.getFirstOnOffVisitDate();
    
    return this.getSmartDeliveryVisitDates().filter((date) => {
      return new Date(date.visitDate) >= firstOnOffVisitDate;
    });
  }

  getOnOffSmartVisitDates(): VisitDate[] {
    return [
      ...this.getOnRouteVisitDates(),
      ...this.getOffRouteVisitDates(),
      ...this.getSmartDeliveryDatesGreaterThanFirstVisitDate(),
    ].sort((a, b) => new Date(a.visitDate).getTime() - new Date(b.visitDate).getTime());
  }

  hasvisitDates(): boolean {
    return this.visitDates.length > 0;
  }

  loadDeliveryDates(): void {
    this.store.dispatch(loadDeliveryDates());
  }

  updateCurrentVisitDateBySelectedDate(selectedVisitDate: VisitDate): void {
    this.store.dispatch(updateDeliveryDate({ date: selectedVisitDate }));
  }

  isSameDeliveryDate(deliveryDate: VisitDate): boolean {
    return DateUtility.isSameDay(deliveryDate.visitDate, this.selectedVisitDate)
  }

  isFirstDeliveryDate(): boolean {
    const visitDateIndex = this.visitDates?.findIndex((date) => {
      return DateUtility.isSameDay(date.visitDate, this.selectedVisitDate);
    });
    return visitDateIndex === 0;
  }

  canShowMinAmountByDates(showMinAmountByDates: boolean): boolean {
    return showMinAmountByDates &&
    (
      this.getOnOffSmartVisitDates()[0].amountMinRoute > 0 ||
      this.getOnOffSmartVisitDates()[0].quantityMinOffRoute > 0
    );
  }
}
