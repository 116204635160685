import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  distinctUntilChanged,
  startWith,
  skip} from 'rxjs/operators';

import { ApiService } from './api.service';
import { UserService } from './user.service';

import { updateClientSuccess } from '../state/actions/client.actions';

import { BERespModel } from '../models/backend/BE-response.model';
import { Client } from '../models/client.model';
import { UserInfo } from '../models/user-info.model';
import { EndpointsCodes } from '../enums/endpoints-codes.enum';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  client: Client;
  subscription = new Subscription();
  get client$(): Observable<Client> {
    return this.store.select('client');
  }
  get user(): UserInfo {
    return this.userService.user;
  }

  constructor(
    private apiSrv: ApiService,
    private store: Store<{ client: Client }>,
    private userService: UserService,
  ) {
    this.setClientFromStore();
  }

  private setClientFromStore(): void {
    this.subscription.add(
      this.client$.subscribe({
        next: (client) => this.client = client,
      }),
    );
  }

  getClientUntilChanged$(): Observable<Client> {
    return this.client$.pipe(
      distinctUntilChanged((prev, curr) => prev.clientId === curr.clientId),
      startWith(null as Client),
      skip(1),
    );
  }

  getClientInfo(): Observable<BERespModel> {
    return new Observable((obs) => {
      this.apiSrv
        .get(`clients?clientId=${this.client.clientId}`, EndpointsCodes.GET_CLIENT, {})
        .subscribe(
          (res) => {
            obs.next(res)
          },
          (err) => obs.error(err),
          () => obs.complete()
        );
    });
  }

  updateClientStore$(): Observable<void> {
    return new Observable<void>((obs) => {
      this.userService.getUserInfo(this.user.cognitoUserName).subscribe({
        next: ({ data }) => {
          this.updateCurrentClient(data);
          obs.next();
        },
        error: (err) => {
          obs.error(err);
        },
      });
    });
  }

  updateCurrentClient(data: UserInfo): void {
    const CLIENT: Client = this.getCurrentClient(data.clients);
    this.store.dispatch(updateClientSuccess({ client: CLIENT }));
  }

  private getCurrentClient(clients: Client[]): Client {
    return clients?.filter((client) => {
      return client.clientId === this.client.clientId;
    })[0];
  }
}