import { Component } from '@angular/core';
import { LoadingScreenService } from 'src/app/core/services/loading-screen.service';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss'],
})
export class LoadingScreenComponent {
  isVisible = this.loadingScreenService.isVisible;

  constructor(private loadingScreenService: LoadingScreenService) {}
}
