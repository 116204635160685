import { Pipe, PipeTransform } from '@angular/core';
import { VisitDate } from '../../models/client.model';
import { VisitDatesService } from '../../services/visit-date.service';

@Pipe({
  name: 'canShowMinAmountByDates',
  standalone: true,
  pure: true,
})
export class CanShowMinAmountByDatesPipe implements PipeTransform {

  constructor(private visitDatesService: VisitDatesService) { }

  transform(showMinAmountByDates: boolean): boolean {
    return this.visitDatesService.canShowMinAmountByDates(showMinAmountByDates);
  }

}
