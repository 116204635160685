import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IButtonConfig } from '@components/button/models/button.model';

export type alertType =
  'primary' |
  'success' |
  'warning' |
  'dark'    |
  'danger';

export enum IconsAlert {
  primary = 'tabler-sprite.svg#tabler-alert-circle',
  success = 'tabler-sprite.svg#tabler-circle-check',
  warning = 'tabler-sprite.svg#tabler-alert-circle',
  dark    = 'alert-white-ex.svg',
  danger = 'tabler-sprite.svg#tabler-circle-x'
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  @Input() type: alertType;
  @Input() text: string;
  @Input() showLeftIcon = false;
  @Input() showRightIcon = false;
  @Input() showMoreContent = false;
  @Input() button: IButtonConfig;
  @Input() customIcon = '';
  @Input() size?:number;
  @Input() circularIconBackground = false;
  @Input() backgroundColor = '';
  @Output() closeAlertEvent = new EventEmitter<void>();
  @Output() buttonOnClick = new EventEmitter<void>();

  get getIconUrl(): string {
    return IconsAlert[this.type] || '';
  }
}
