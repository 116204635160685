import { Pipe, PipeTransform } from '@angular/core';
import { VisitDatesService } from '../../services/visit-date.service';
import { VisitDate } from '../../models/client.model';
import { SMART_DELIVERY } from '../../services/visit-dates/constants/visit-dates.constants';

@Pipe({
  standalone: true,
  name: 'canShowLabelAditionalSmartDate'
})
export class CanShowLabelAditionalSmartDatePipe implements PipeTransform {

  constructor(private visitDatesService: VisitDatesService) { }

  transform(date: VisitDate): boolean {
    return date?.source && date?.source === SMART_DELIVERY &&
      new Date(date?.visitDate) > this.visitDatesService.getFirstOnOffVisitDate();
  }
}
