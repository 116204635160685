import { Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, Subscription } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { ProductsService } from '../../services/products.service';
import * as PortfolioActions from './../actions/portfolio.actions';
import { BERespModel } from '../../models/backend/BE-response.model';
import { PortfolioFilters } from '../../models/portfolio-filters.model';
import { Portfolio } from '../../models/portfolio.model';
import { Store } from '@ngrx/store';
import { PortfolioService } from '../../services/portfolio.service';

@Injectable({
  providedIn: 'root',
})
export class PortfolioEffects implements OnDestroy {
  private subscriptions = new Subscription();
  private portfolio: Portfolio;

  constructor(
    private productsService: ProductsService,
    private actions$: Actions,
    private portFolioService: PortfolioService,
  ) {
    this.subscriptions.add(
      this.portFolioService.portoflio$.subscribe((portfolio) => this.portfolio),
    );
  }

  loadPortfolio$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PortfolioActions.loadPortfolio,
        PortfolioActions.updateSelectedProductFilter,
      ),
      mergeMap((props) =>
        this.productsService
          .getPortfolioWithDiscountByClient(
            true,
            this.portfolio.selectedFilter,
            this.portFolioService.calculatePortfolioOffset(this.portfolio.page),
          )
          .pipe(
            switchMap((res: BERespModel) => {
              return [
                PortfolioActions.loadPortfolioSuccess({
                  products: res.data,
                  page: res.pagination.currentpage,
                  totalProducts: res.pagination.count,
                }),
              ];
            }),
            catchError((error) => {
              return of(PortfolioActions.loadPortfolioError({ error }));
            }),
          ),
      ),
    ),
  );

  loadProductsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PortfolioActions.loadPortfolioFilter),
      mergeMap((props) =>
        this.productsService.getPortfolioFilters(props.productFilter).pipe(
          switchMap((res: BERespModel) => {
            const portfolioFilters: PortfolioFilters = {
              brands: res.data?.brands,
              categories: res.data?.categories,
              packages: res.data?.packages,
              sizes: res.data?.sizes,
              returnabilities: res.data?.returnabilities,
              principalFilters: res.data?.principalFilters,
            };
            return [
              PortfolioActions.loadPortfolioFilterSuccess({ portfolioFilters }),
            ];
          }),
          catchError((error) => {
            return of(PortfolioActions.loadPortfolioFilterError);
          }),
        ),
      ),
    ),
  );

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
