import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationInfo } from 'src/app/core/models/notification-info.type';
import { CartService } from 'src/app/core/services/cart.service';

@Component({
  selector: 'app-alert-notifications',
  templateUrl: './alert-notifications.component.html',
  styleUrls: ['./alert-notifications.component.scss'],
})
export class AlertNotificationsComponent implements OnDestroy {
  @Input() up? = true;
  @Input() mobile? = false;
  notification: NotificationInfo;
  showNotification = false;
  private subscriptions = new Subscription();
  private timeout: any;

  constructor(
    private cartService: CartService,
    private cdr: ChangeDetectorRef,
  ) {
    this.subscriptions.add(
      this.cartService.getSharingNotifications().subscribe((notification) => {
        this.notification = notification;
        this.showNotification = true;
        this.cdr.detectChanges();
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.showNotification = false;
          this.cdr.detectChanges();
        }, notification.delay);
      }),
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    clearTimeout(this.timeout);
  }
}
