import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';

import { RadioGroup } from '@components/radio/directives/radio-group.directive';
import { RadioComponent } from '@components/radio/radio.component';

import { Client, VisitDate } from 'src/app/core/models/client.model';

import { ComponentsModule } from '@components/components.module';

import { WeekdayDayMonthParserPipe } from '../../pipes/week-day-day-month.pipe';
import { CurrencyPipe } from '../../pipes/currency.pipe';
import { BoxesPipe } from '../../pipes/boxes.pipe';
import { CanShowLabelAditionalSmartDatePipe } from 'src/app/core/pipes/can-show-label-aditional-smart-date/can-show-label-aditional-smart-date.pipe';

import { SMART_DELIVERY } from 'src/app/core/services/visit-dates/constants/visit-dates.constants';
import { CountryCodes } from 'src/app/core/enums/country-codes.enum';

import { VisitDatesService } from 'src/app/core/services/visit-date.service';
import { ClientService } from 'src/app/core/services/client.service';
import { MinAmountService } from 'src/app/core/services/min-amount/min-amount.service';

import { env } from 'src/app/app.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RadioComponent,
    RadioGroup,
    ReactiveFormsModule,
    WeekdayDayMonthParserPipe,
    TranslateModule,
    BoxesPipe,
    ComponentsModule,
    CanShowLabelAditionalSmartDatePipe,
    CurrencyPipe,
  ],
  selector: 'app-options-delivery-date',
  templateUrl: './options-delivery-date.component.html',
  styleUrls: ['./options-delivery-date.component.scss'],
})
export class OptionsDeliveryDateComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription;
  readonly countryCodes = CountryCodes;
  readonly SMART_DELIVERY = SMART_DELIVERY;
  
  get ambientSmartDeliveryVisitDates(): VisitDate[] {
    return this.visitDatesService.getSmartDeliveryVisitDatesLessThanFirstVisitDate();
  }

  get ambientOnOffSmartVisitDates(): VisitDate[] {
    return this.visitDatesService.getOnOffSmartVisitDates();
  }

  get client$(): Observable<Client> {
    return this.clientService.getClientUntilChanged$();
  }

  get showMinAmountByDates(): boolean {
    return env.getConfigByCountry()?.showMinAmountByDates;
  }

  get showGreenTextMinAmount(): boolean {
    return env.getConfigByCountry()?.showGreenTextMinAmount;
  }
  
  @Output() selectedVisitDate = new EventEmitter<VisitDate>();
  control: FormControl = this.formBuilder.control('');

  constructor(
    private visitDatesService: VisitDatesService,
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private minAmountService: MinAmountService,
  ) { }

  ngOnInit(): void {
    this.getVisitDatesByClient();
    this.setUpForm();
    this.setAmbientMinAmount();
  }

  private getVisitDatesByClient(): void {
    this.subscriptions.add(this.visitDatesService.getVisitDatesByClient());
  }

  private setUpForm(): void {
    this.subscriptions.add(
      this.visitDatesService.clientCartStream$.subscribe({
        next: () => {
          this.control = this.formBuilder.control(this.getInitialValue());
          this.updateSelectVisitDate();

          if (this.visitDatesService.visitDates.length === 1) {
            this.control.disable();
          }
        },
      })
    );
  }

  private getInitialValue(): VisitDate {
    let initialControlValue: VisitDate;

    this.visitDatesService.visitDates?.forEach((date) => {
      if (this.visitDatesService.isSameDeliveryDate(date)) {
        initialControlValue = date;
      }
    });

    return initialControlValue
      ? initialControlValue
      : this.visitDatesService.visitDates[0];
  }

  private updateSelectVisitDate(): void {
    this.subscriptions.add(
      this.control.valueChanges.pipe(
        startWith(this.control.value),
        distinctUntilChanged((prev: VisitDate, curr: VisitDate) => {
          return prev.visitDate === curr.visitDate;
        }),
      ).subscribe({
        next: (value) => {
          this.setAmbientMinAmount();
          this.selectedVisitDate.emit(value)
        },
      })
    );
  }

  private setAmbientMinAmount(): void {
    this.minAmountService.setAmbientMinAmount(
      this.visitDatesService.getSelectedVisitDate()
    );
  }

  trackByDates(date: VisitDate): string {
    return date.visitDate;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
