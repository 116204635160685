import { Injectable } from '@angular/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';

import { getCartFrozenVisitDate } from '../state/reducers/cart.reducer';
import { getClientFrozenVisitDates } from '../state/reducers/client.reducer';
import {
  updateFrozenVisitDate,
  setFrozenOperationDate,
  updateDeliveryDate,
  loadFrozenDeliveryDates,
} from '../state/actions/cart.actions';

import { VisitDate } from '../models/client.model';
import { DateUtility } from '../utils/app-date-utils';

@Injectable({
  providedIn: 'root'
})
export class VisitFrozenDatesService {
  visitDates: VisitDate[];
  selectedVisitDate: string | Date;
  get clientCartStream$(): Observable<[string | Date, VisitDate[]]> {
    return combineLatest([
      this.store.select(getCartFrozenVisitDate),
      this.store.select(getClientFrozenVisitDates),
    ]);
  }

  constructor(private store: Store) { }

  getVisitDatesByClient(): Subscription {
    return this.clientCartStream$.subscribe({
      next: ([
        cartFrozenVisitDate,
        visitDates,
      ]) => {
        this.setVisitDates(visitDates, cartFrozenVisitDate);
      },
    })
  }

  private setVisitDates(
    visitDates: VisitDate[],
    cartFrozenVisitDate: string | Date
  ): void {
    this.visitDates = visitDates;
    if (!this.visitDates) {
      return;
    }

    this.selectedVisitDate = this.getCurrentVisitDate(cartFrozenVisitDate);
  }

  private getCurrentVisitDate(cartFrozenVisitDate: string | Date): string | Date {
    if (!cartFrozenVisitDate) {
      return this.visitDates[0]?.visitDate
        ? DateUtility.formatDateToISOString(this.visitDates[0]?.visitDate)
        : null;
    }

    return DateUtility.formatDateToISOString(cartFrozenVisitDate);
  }

  getSelectedVisitDate(): VisitDate {
    return this.visitDates.filter((date) => {
      return DateUtility.isSameDay(date.visitDate, this.selectedVisitDate)
    })[0];
  }

  updateVisitDate(): void {
    this.store.dispatch(
      updateFrozenVisitDate({ date: this.selectedVisitDate })
    );
  }

  updateVisitDateBySelect(selectedVisitDate: string | Date): void {
    this.store.dispatch(updateFrozenVisitDate({ date: selectedVisitDate }));
  }

  setOperationDate(): void {
    this.store.dispatch(setFrozenOperationDate());
  }

  isSameDeliveryDate(deliveryDate: VisitDate): boolean {
    return DateUtility.isSameDay(deliveryDate.visitDate, this.selectedVisitDate)
  }

  updateCurrentVisitDateBySelectedDate(selectedVisitDate: VisitDate): void {
    this.store.dispatch(updateDeliveryDate({ date: selectedVisitDate }));
  }

  loadDeliveryDates(): void {
    this.store.dispatch(loadFrozenDeliveryDates());
  }
}
