import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { ModalMobileDeliveryDateService } from '../select-delivery-date/services/modal-mobile-delivery-date.service';
import { VisitDatesService } from 'src/app/core/services/visit-date.service';
import { IsMobileService } from 'src/app/core/services/is-mobile/is-mobile.service';
import { ModalDesktopDeliveryDateService } from '../select-delivery-date/services/modal-desktop-delivery-date.service';

import { WeekdayDayMonthParserPipe } from 'src/app/shared/pipes/week-day-day-month.pipe';
import { VisitDate } from 'src/app/core/models/client.model';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    WeekdayDayMonthParserPipe,
  ],
  selector: 'app-show-delivery-date',
  templateUrl: './show-delivery-date.component.html',
  styleUrls: ['./show-delivery-date.component.scss'],
  host: {
    '[class.show-delivery-date]': 'true',
    '(click)': 'openModal()',
  }
})
export class ShowDeliveryDateComponent implements OnInit, OnDestroy {
  private subscription = new Subscription;
  get clientVisitDates(): VisitDate[] {
    return this.visitDateService.visitDates;
  }
  get currentVisitDate(): string | Date {
    return this.visitDateService.selectedVisitDate;
  }
  get isEmptyDeliveryDate(): boolean {
    return !this.clientVisitDates || this.clientVisitDates.length === 0;
  }
  get isOneDeliveryDate(): boolean {
    return this.clientVisitDates?.length === 1;
  }
  get isMoreThanOneDeliveryDate(): boolean {
    return this.clientVisitDates?.length > 1;
  }

  constructor(
    private _modalMobileDeliveryDateService: ModalMobileDeliveryDateService,
    private _modalDesktopDeliveryDateService: ModalDesktopDeliveryDateService,
    private visitDateService: VisitDatesService,
    private isMobileService: IsMobileService,
  ) { }

  ngOnInit(): void {
    this.getVisitDatesByClient();
  }

  private getVisitDatesByClient(): void {
    this.subscription.add(this.visitDateService.getVisitDatesByClient());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openModal(): void {
    if (this.canOpenModalMobile()) {
      this._modalMobileDeliveryDateService.openModal();
    }
    if (this.canOpenModalDesktop()) {
      this._modalDesktopDeliveryDateService.openModal();
    }
  }

  private canOpenModalMobile(): boolean {
    return this.isMobileService.isMobile
      && this.clientVisitDates && this.clientVisitDates.length > 1;
  }

  private canOpenModalDesktop(): boolean {
    return !this.isMobileService.isMobile
      && this.clientVisitDates && this.clientVisitDates.length > 1;
  }
}

