import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Portfolio } from '../models/portfolio.model';
import { Observable } from 'rxjs';
import * as PortfolioActions from '../state/actions/portfolio.actions';
import { ProductFilter } from '../models/product-filter.model';

@Injectable({
  providedIn: 'root',
})
export class PortfolioService {
  portfolio: Portfolio;
  get portoflio$(): Observable<Portfolio> {
    return this.store.select('portfolio');
  }

  constructor(private store: Store<{ portfolio: Portfolio }>) {
    this.store
      .select('portfolio')
      .subscribe((portfolio) => (this.portfolio = portfolio));
  }

  calculatePortfolioOffset(page: number): number {
    return this.portfolio.productsAndDiscountsList.length * (page - 1);
  }

  updatePortfolioFilters(productFilter: ProductFilter): void {
    this.store.dispatch(
      PortfolioActions.loadPortfolioFilter({ productFilter }),
    );
  }

  selectFilter(selectedFilter: ProductFilter): void {
    this.store.dispatch(PortfolioActions.updateSelectedProductFilter({selectedFilter}))
  }
}
