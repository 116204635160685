import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { fromEvent, Subscription } from 'rxjs';

import { CountryCodes } from './core/enums/country-codes.enum';
import { Client } from './core/models/client.model';
import { UserInfo } from './core/models/user-info.model';
import { UserLocal } from './core/models/user-local.model';
import { GeoLocationService } from './core/services/geo-location.service';
import * as UserLocalActions from './core/state/actions/user-local.actions';
import { environment } from 'src/environments/environment';
import { EncrDecrService } from './core/services/encr-decr.service';
import { GoBackService } from './core/services/go-back.service';
import { CheckForUpdateService } from './core/services/pwa/check-for-update.service';
import { TranslationService } from './core/services/translation.service';

import { EnvironmentUtils } from 'src/environments/utils/env.class';
import { countries } from 'src/environments/utils/env.model';
import { debounceTime } from 'rxjs/operators';
import { CognitoService } from './core/services/cognito.service';
import { HeadDocumentService } from './core/services/head-document.service';
import { transformUrl } from './shared/utils/url-transformer';
import { ClarityService } from './core/services/clarity.service';

export const env = new EnvironmentUtils();
env.setEnvironment(environment);
env.setCountry('CL');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'cocacola-andina';
  private subscriptions = new Subscription();
  user: UserInfo;
  userLocal: UserLocal;
  client: Client;
  currentRoute: string;
  readonly CountryCodes = CountryCodes;

  constructor(
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private store: Store<{
      user: UserInfo;
      client: Client;
      userLocal: UserLocal;
    }>,
    private geoLocationSrv: GeoLocationService,
    private encrDecrService: EncrDecrService,
    private navigation: GoBackService,
    private translationService: TranslationService,
    private cognitoService: CognitoService,
    private headDocumentService: HeadDocumentService,
    private clarityService: ClarityService
  ) {
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => {
        this.user = user;
        if (this.user.countryId) {
          this.translationService.setLanguage(this.user.countryId as countries);
          env.setCountry(this.user.countryId as countries);
        }
      }),
    );
    this.subscriptions.add(
      this.store.select('userLocal').subscribe((userLocal) => {
        if (!userLocal.geoCountryCode) {
          return;
        }

        this.userLocal = userLocal;
        this.translationService.setLanguage(
          this.userLocal.geoCountryCode as countries,
        );
        env.setCountry(this.userLocal.geoCountryCode as countries);
        this.cognitoService.refreshUserPoolInstance();
      }),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => (this.client = client)),
    );

    new CheckForUpdateService();
    this.navigation.startSaveHistory();
    this.translationService.init();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.loadCountryOrCountryCode();
    this.calcWindowHeight();
    this.clarityService.initClarity();

    fromEvent(window, 'resize')
      .pipe(debounceTime(250))
      .subscribe(() => this.calcWindowHeight());
  }

  calcWindowHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  loadCountryOrCountryCode(): void {
    const userCountry = this.user?.countryId || this.userLocal?.geoCountryCode;

    if (userCountry) {
      this.loadCountry(userCountry);
    } else {
      this.loadCountryCodeByGeo();
    }
  }

  loadCountryCodeByGeo(): void {
    this.geoLocationSrv.getCountryCode().subscribe(
      (res) => this.loadCountry(res),
      () => this.loadCountry(CountryCodes.CHILE),
    );
  }

  private loadCountry(countryCode): void {
    const selectedCountryCode = Object.values(CountryCodes).includes(countryCode)
      ? countryCode
      : CountryCodes.CHILE;
    this.headDocumentService.setTitleByCountry(
      selectedCountryCode as CountryCodes,
    );
    env.setCountry(selectedCountryCode);
    const selectedCountry = env.getConfigByCountry();
    this.store.dispatch(
      UserLocalActions.loadGeoCountryCode({ countryCode: selectedCountryCode }),
    );
    this.store.dispatch(
      UserLocalActions.loadOrganizationId({
        organizationId: selectedCountry.organizationId,
      }),
    );
    this.store.dispatch(
      UserLocalActions.loadCpgId({ cpgId: selectedCountry.cpgId }),
    );
    this.initGTMRouterEvents();
  }

  initGTMRouterEvents(): void {
    this.router.events.forEach((item) => {
      const countryLocal = env.getConfigByCountry();

      if (item instanceof NavigationEnd && item.url !== this.currentRoute) {
        this.currentRoute = item.url;
        let userInfo;

        if (this.user?.userId) {
          const encryptedEmail = this.encrDecrService.encryptMCC(
            environment.AES_KEY_MCC,
            this.user.email,
          );
          userInfo = {
            ...this.user,
            jwt: '',
            clients: [],
            mail: encryptedEmail,
          };
        }

        const transformedUrl = transformUrl(item.url);

        const gtmTag = {
          event: 'ngRouteChange',
          pageName: transformedUrl,
          user: userInfo,
          client: this.client?.clientId ? this.client : undefined,
          country: {
            isoCodeCountry: countryLocal?.key,
            labelCountry: countryLocal?.label,
          },
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }
}
