<div class="loading-overlay" *ngIf="isVisible()">
  <div class="loading-container">
    <div class="spinner"></div>
    <p class="principal-text">
      {{ 'NEW_ORDER.LOADING_SCREEN.PRINCIPAL_TEXT' | translate }}
    </p>
    <br />
    <p class="secondary-text">
      {{ 'NEW_ORDER.LOADING_SCREEN.SECONDARY_TEXT' | translate }}
    </p>
  </div>
</div>
