<div [ngClass]="{ 'conteiner-up': up || !mobile, 'conteiner-down': !up }">
  <app-alert
    *ngIf="showNotification"
    [type]="notification['alert-type']"
    [text]="notification['text'] | translate : { missingQuantity: notification['missingQuantity'], percentage: notification['percentage'] }"
    [size]="12"
    [showLeftIcon]="true"
    [customIcon]="notification['icon']"
    [circularIconBackground]="notification['circularIconBackground']"
    [backgroundColor]="notification['backgroundColor']"
  ></app-alert>
</div>