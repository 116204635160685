import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalMobileSelectCommerceService } from './service/modal-mobile-select-commerce.service';
import { IsMobileService } from '../../../core/services/is-mobile/is-mobile.service';
import { SelectClientService } from '../../../core/services/select-client.service';
import { Client } from '../../../core/models/client.model';
import { UserInfo } from '../../../core/models/user-info.model';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { env } from '../../../app.component';
import { FEATURES } from '../../../../environments/utils/env.model';

@Component({
  selector: 'app-ka-select-commerce',
  templateUrl: './ka-select-commerce.component.html',
  styleUrls: ['./ka-select-commerce.component.scss'],
})
export class KaSelectCommerceComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  clients: Client[];
  selectedClient: Client;
  user: UserInfo;
  title = 'HOME.COMMERCES.TITLE';
  cancel = 'HOME.COMMERCES.CANCEL';

  get isMobile(): boolean {
    return this._isMobileService.isMobile;
  }

  constructor(
    private store: Store<{ user: UserInfo; client: Client }>,
    private selectClientService: SelectClientService,
    private _isMobileService: IsMobileService,
    private _modalMobileSelectCommerceService: ModalMobileSelectCommerceService,
  ) {
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => {
        this.clients = user.clients;
        this.setCommerceInfo();
        this.user = user;
      }),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => {
        this.selectedClient = client;
        this.setCommerceInfo();
      }),
    );
  }

  ngOnInit(): void {
    this.setCommerceInfo();
  }

  setCommerceInfo() {
    if (!this.clients) return;

    this.clients = this.clients.filter(client => client !== null && client !== undefined);

    const useFantasyName = env.isFeatureAvailable(
      FEATURES.COMMERCE_FANTASY_NAME,
    );

    this.clients = this.clients.map((client) => ({
      ...client,
      commerceInfo:
        useFantasyName && client?.fantasyName
          ? client?.fantasyName
          : `${client.street ?? ''} ${client.doorNumber ?? ''}`,
    }));

    if (useFantasyName) {
      this.clients.sort((a, b) => {
        if (a.fantasyName && b.fantasyName) {
          return a.fantasyName.localeCompare(b.fantasyName);
        }
        if (a?.fantasyName && !b?.fantasyName) return -1;
        if (!a?.fantasyName && b?.fantasyName) return 1;
        return 0;
      });
    }
  }

  selectClient(client: Client): void {
    if (client.clientId === this.selectedClient.clientId) {
      return;
    }
    this.selectClientService.selectClient(client);
    this.closeModal();
  }

  closeModal(): void {
    this._modalMobileSelectCommerceService.closeModal();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}