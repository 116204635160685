import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { Locks } from '../../enums/locks.enum';
import { Client, VisitDate } from '../../models/client.model';
import { PaymentMethod } from '../../models/payment-method.model';
import * as ClientActions from '../actions/client.actions';
import { Product } from '../../models/product.model';

import { DateUtility } from '../../utils/app-date-utils';

const initialState: Client = {
  fantasyName: '',
  clientId: 0,
  erpClientId: '0',
  fiscalId: '0',
  rol: '',
  hasLockOrder: false,
  lockOrderMissingPayment: false,
  subUnitAvailable: false,
  hasLockProductCategory: false,
  data: {
    visitDates: [],
    frozenVisitDates: [],
    orders: [],
    suggestedProducts: { products: [], amount: 0 },
    discountProducts: [],
    discounts: [],
    credits: [],
    drafts: [],
    paymentMethod: [],
    mainPaymentMethod: {},
    hasCreditLock: false,
    lockNextPaymentMethod: false,
    showCreditBalance: true,
    canPayCredits: true,
  },
  discountProductsforOffert: [],
};

const getCartFeatureState = createFeatureSelector<Client>('client');

export const getSuggestedProducts = createSelector(
  getCartFeatureState,
  (state) => state.data.suggestedProducts,
);

export const clientReducer = createReducer<Client>(
  initialState,
  on(
    ClientActions.loadClient,
    (state): Client => ({ ...state, data: { ...state.data } }),
  ),
  on(ClientActions.loadClientSuccess, (state, props): Client => {
    const hasLockProductCategory = props.client.locks.some(
      (lock) => lock?.lockProducts,
    );
    return {
      ...state,
      ...props.client,
      hasLockProductCategory,
    };
  }),
  on(
    ClientActions.loadClientError,
    (state): Client => ({ ...state, data: { ...state.data } }),
  ),

  on(
    ClientActions.updateClient,
    (state): Client => ({ ...state, data: { ...state.data } }),
  ),
  on(ClientActions.updateClientSuccess, (state, props): Client => {
    const hasCreditLock = props.client.locks
      ? props.client.locks.some(
          (lock) => lock.description === Locks.CREDIT_LOCK,
        )
      : false;
    return {
      ...state,
      ...props.client,
      data: {
        ...state.data,
        hasCreditLock,
      },
    };
  }),
  on(
    ClientActions.updateClientError,
    (state): Client => ({ ...state, data: { ...state.data } }),
  ),

  on(
    ClientActions.loadVisitDates,
    (state): Client => ({ ...state, data: { ...state.data } }),
  ),
  on(
    ClientActions.loadVisitDatesSuccess,
    (state, props): Client => ({
      ...state,
      data: { ...state.data, visitDates: props.visitDates },
    }),
  ),
  on(
    ClientActions.loadVisitDatesError,
    (state): Client => ({ ...state, data: { ...state.data, visitDates: [] } }),
  ),

  on(
    ClientActions.updateOperationDatesSuccess,
    (state): Client => ({ ...state, data: { ...state.data } }),
  ),

  on(
    ClientActions.loadInitClientSession,
    (state): Client => ({ ...state, data: { ...state.data } }),
  ),
  on(
    ClientActions.loadInitClientSessionSuccess,
    (state): Client => ({
      ...state,
      data: { ...state.data, initClientSession: true },
    }),
  ),
  on(
    ClientActions.loadInitClientSessionError,
    (state): Client => ({
      ...state,
      data: { ...state.data, initClientSession: false },
    }),
  ),

  on(
    ClientActions.loadFrozenVisitDates,
    (state): Client => ({ ...state, data: { ...state.data } }),
  ),
  on(
    ClientActions.loadFrozenVisitDatesSuccess,
    (state, props): Client => ({
      ...state,
      data: { ...state.data, frozenVisitDates: props.frozenVisitDates },
    }),
  ),
  on(
    ClientActions.loadFrozenVisitDatesError,
    (state): Client => ({
      ...state,
      data: { ...state.data, frozenVisitDates: [] },
    }),
  ),

  on(ClientActions.loadOrders, (state): Client => ({ ...state })),
  on(
    ClientActions.loadOrdersSuccess,
    (state, props): Client => ({
      ...state,
      data: { ...state.data, orders: props.orders },
    }),
  ),
  on(
    ClientActions.loadOrdersError,
    (state): Client => ({ ...state, data: { ...state.data, orders: [] } }),
  ),

  on(ClientActions.loadLastOrder, (state): Client => ({ ...state })),
  on(
    ClientActions.loadLastOrderSuccess,
    (state, props): Client => ({
      ...state,
      data: { ...state.data, lastOrder: props.lastOrder },
    }),
  ),
  on(
    ClientActions.loadLastOrderError,
    (state): Client => ({
      ...state,
      data: { ...state.data, lastOrder: undefined },
    }),
  ),

  on(ClientActions.loadSuggestedProducts, (state): Client => ({ ...state })),
  on(
    ClientActions.loadSuggestedProductsSuccess,
    (state, props): Client => ({
      ...state,
      data: {
        ...state.data,
        suggestedProducts: {
          products: props.suggestedProducts.products,
          amount: props.suggestedProducts.amount,
        },
      },
    }),
  ),
  on(
    ClientActions.loadSuggestedProductsError,
    (state): Client => ({
      ...state,
      data: { ...state.data, suggestedProducts: { products: [], amount: 0 } },
    }),
  ),

  on(ClientActions.loadDiscountProducts, (state): Client => ({ ...state })),
  on(
    ClientActions.loadDiscountProductsSuccess,
    (state, props): Client => ({
      ...state,
      data: { ...state.data, discountProducts: props.discountProducts },
    }),
  ),
  on(
    ClientActions.loadDiscountProductsError,
    (state): Client => ({
      ...state,
      data: { ...state.data, discountProducts: [] },
    }),
  ),

  on(
    ClientActions.loadDiscretionaryDiscount,
    (state): Client => ({ ...state }),
  ),
  on(
    ClientActions.loadDiscretionaryDiscountSuccess,
    (state, props): Client => ({
      ...state,
      data: { ...state.data, discounts: props.discounts },
    }),
  ),
  on(
    ClientActions.loadDiscretionaryDiscountError,
    (state): Client => ({ ...state, data: { ...state.data, discounts: [] } }),
  ),

  on(ClientActions.loadDrafts, (state): Client => ({ ...state })),
  on(
    ClientActions.loadDraftsSuccess,
    (state, props): Client => ({
      ...state,
      data: { ...state.data, drafts: props.drafts },
    }),
  ),
  on(
    ClientActions.loadDraftsError,
    (state): Client => ({ ...state, data: { ...state.data, drafts: [] } }),
  ),
  on(ClientActions.deleteDraft, (state): Client => ({ ...state })),
  on(
    ClientActions.deleteDraftSuccess,
    (state, props): Client => ({
      ...state,
      data: {
        ...state.data,
        drafts: state.data.drafts.filter(
          (draft) => draft.orderId !== props.draft.orderId,
        ),
      },
    }),
  ),
  on(ClientActions.deleteDraftError, (state): Client => ({ ...state })),

  on(ClientActions.loadPaymentMethod, (state): Client => ({ ...state })),
  on(ClientActions.loadPaymentMethodSuccess, (state, props): Client => {
    const metaProperties = processPaymentMethods(props.paymentMethods);
    return {
      ...state,
      data: {
        ...state.data,
        ...metaProperties,
        paymentMethod: props.paymentMethods,
      },
    };
  }),
  on(
    ClientActions.loadPaymentMethodError,
    (state): Client => ({
      ...state,
      data: { ...state.data, paymentMethod: [] },
    }),
  ),

  on(ClientActions.loadCredits, (state, props): Client => ({ ...state })),
  on(
    ClientActions.loadCreditsSuccess,
    (state, props): Client => ({
      ...state,
      data: { ...state.data, credits: props.credits },
    }),
  ),
  on(
    ClientActions.loadCreditsError,
    (state): Client => ({ ...state, data: { ...state.data, credits: [] } }),
  ),

  on(
    ClientActions.setClientHasLockOrder,
    (state, props): Client => ({ ...state, hasLockOrder: props.hasLockOrder }),
  ),
  on(
    ClientActions.lockOrderMissingPayment,
    (state, props): Client => ({
      ...state,
      lockOrderMissingPayment: props.lockOrderMissingPayment,
    }),
  ),
  on(
    ClientActions.setLockType,
    (state, props): Client => ({ ...state, lockType: props.lockType }),
  ),

  on(ClientActions.checkClientLocks, (state): Client => ({ ...state })),
  on(ClientActions.checkClientLocksSuccess, (state): Client => ({ ...state })),
  on(ClientActions.checkClientLocksError, (state): Client => ({ ...state })),

  on(
    ClientActions.updateHasCreditLock,
    (state, props): Client => ({
      ...state,
      data: { ...state.data, hasCreditLock: props.hasCreditLock },
    }),
  ),
  on(
    ClientActions.updateSuggestedFavoriteProduct,
    (state, props): Client => ({
      ...state,
      data: {
        ...state.data,
        suggestedProducts: {
          ...state.data.suggestedProducts,
          products: handleFavoriteSuggestedProduct(state, props.product),
        },
      },
    }),
  ),
  on(
    ClientActions.loadDiscountProductsForOffert,
    (state): Client => ({ ...state }),
  ),
  on(
    ClientActions.loadDiscountProductsForOffertError,
    (state): Client => ({ ...state, discountProductsforOffert: [] }),
  ),
  on(
    ClientActions.loadDiscountProductsForOffertSuccess,
    (state, props): Client => ({
      ...state,
      discountProductsforOffert: props.discountProductsforOffert,
    }),
  ),
  on(ClientActions.updateExternalClientInfo, (state, props): Client => {
    const hasCreditLock = props.client.locks
      ? props.client.locks.some(
          (lock) => lock.description === Locks.CREDIT_LOCK,
        )
      : false;
    return {
      ...state,
      ...props.client,
      data: {
        ...state.data,
        hasCreditLock,
      },
    };
  }),
  on(ClientActions.updateAmbientMinAmountBySelectedDate, (state, props): Client => {
    const visitDates = state.data.visitDates;
    const currentSelectedVisitDate = props.data.date.visitDate;
    const minAmount = props.data.amountMinRoute;
    const showMinAmountByDates = props.data.showMinAmountByDates;
    
    const currentVisitDate: VisitDate  = visitDates.filter((date) => {
      return DateUtility.isSameDay(date.visitDate, currentSelectedVisitDate)
    })[0];
    currentVisitDate.amountMinRoute = minAmount;

    visitDates.map((date) => {
      if (showMinAmountByDates) {
        if (DateUtility.isSameDay(date.visitDate, currentVisitDate.visitDate)) {
          date = currentVisitDate
        }
      } else {
        date.amountMinRoute = minAmount;
      }
    });

    return {
      ...state,
      data: {
        ...state.data,
        visitDates: visitDates,
      }
    };
  }),
  on(ClientActions.updateFrozenMinAmountBySelectedDate, (state, props): Client => {
    const frozenVisitDates = state.data.frozenVisitDates;
    const currentSelectedVisitDate = props.data.date.visitDate;
    const minAmount = props.data.amountMinFrozen;
    const showMinAmountByDates = props.data.showMinAmountByDates;
    
    const currentVisitDate: VisitDate  = frozenVisitDates.filter((date) => {
      return DateUtility.isSameDay(date.visitDate, currentSelectedVisitDate)
    })[0];
    currentVisitDate.amountMinFrozen = minAmount;

    frozenVisitDates.map((date) => {
      if (showMinAmountByDates) {
        if (DateUtility.isSameDay(date.visitDate, currentVisitDate.visitDate)) {
          date = currentVisitDate
        }
      } else {
        date.amountMinFrozen = minAmount;
      }
    });

    return {
      ...state,
      data: {
        ...state.data,
        frozenVisitDates: frozenVisitDates,
      }
    };
  }),
);

const processPaymentMethods = (paymentMethods: PaymentMethod[]) => {
  const mainPaymentMethod = paymentMethods[0];
  const lockNextPaymentMethod = Boolean(
    mainPaymentMethod.lockNextPaymentMethodUntilBalanceIsReached,
  );
  const showCreditBalance = Boolean(mainPaymentMethod.showCreditBalance);
  const creditPaymentMethod = paymentMethods.find(
    (paymentMethod) => paymentMethod.showCreditBalance === true,
  );
  const cashPaymentMethod = paymentMethods.find(
    (paymentMethod) => paymentMethod.showCreditBalance === false,
  );
  return {
    mainPaymentMethod,
    lockNextPaymentMethod,
    showCreditBalance,
    cashPaymentMethod,
    creditPaymentMethod,
  };
};

const handleFavoriteSuggestedProduct = (
  state: Client,
  product: Product,
): Product[] => {
  const suggestedProducts: Product[] = state.data.suggestedProducts.products;
  const result = suggestedProducts.map((suggestedProduct) => {
    if (suggestedProduct.productId === product.productId)
      return { ...suggestedProduct, isFavorite: !suggestedProduct.isFavorite };
    return suggestedProduct;
  });
  return result;
};

//custom selectors
export const getClientFeatureState = createFeatureSelector<Client>('client');

export const getClientVisitDates = createSelector(
  getClientFeatureState,
  (state) => state.data?.visitDates,
);
export const getClientFrozenVisitDates = createSelector(
  getClientFeatureState,
  (state) => state.data?.frozenVisitDates,
);
export const getDrafts = createSelector(
  getClientFeatureState,
  (state) => state.data?.drafts,
);
