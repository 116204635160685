import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ComponentsModule } from '@components/components.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';

import { ModalMobileDeliveryDateService } from './services/modal-mobile-delivery-date.service';
import { VisitDatesService } from 'src/app/core/services/visit-date.service';
import { IsMobileService } from 'src/app/core/services/is-mobile/is-mobile.service';
import { SelectDeliveryDateInfoService } from './services/select-delivery-date-info.service';
import { ModalDesktopDeliveryDateService } from './services/modal-desktop-delivery-date.service';
import { MinAmountService } from 'src/app/core/services/min-amount/min-amount.service';

import { CurrencyPipe } from '../../pipes/currency.pipe';
import { BoxesPipe } from '../../pipes/boxes.pipe';
import { CanShowMinAmountByDatesPipe } from 'src/app/core/pipes/can-show-min-amount-by-dates/can-show-min-amount-by-dates.pipe';

import { VisitDate } from 'src/app/core/models/client.model';

import { OptionsDeliveryDateComponent } from '../options-delivery-date/options-delivery-date.component';
import { ButtonComponent } from '@components/button/button.component';

import { env } from 'src/app/app.component';

@Component({
  standalone: true,
  imports: [
    ComponentsModule,
    TranslateModule,
    OptionsDeliveryDateComponent,
    CommonModule,
    ButtonComponent,
    CurrencyPipe,
    BoxesPipe,
    CanShowMinAmountByDatesPipe,
  ],
  selector: 'app-select-delivery-date',
  templateUrl: './select-delivery-date.component.html',
  styleUrls: ['./select-delivery-date.component.scss']
})
export class SelectDeliveryDateComponent implements OnInit, OnDestroy {
  private selectedAmbientVisitDate: VisitDate;
  private subscriptions = new Subscription;
  
  get isMobile(): boolean {
    return this.isMobileService.isMobile;
  }
  
  get title(): string {
    return this.selectDeliveryDateInfoService.getTitle();
  }
  
  get subtitle(): string {
    return this.selectDeliveryDateInfoService.getSubTitle();
  }
  
  get showCloseItems(): boolean {
    return this.modalMobileDeliveryDateService.canCloseWithShadow
      && this.modalDesktopDeliveryDateService.backdrop === true;
  }
  
  get ambientOnOffSmartVisitDates(): VisitDate[] {
    return this.visitDatesService.getOnOffSmartVisitDates();
  }

  get showMinAmountByDates(): boolean {
    return env.getConfigByCountry()?.showMinAmountByDates;
  }

  @Output() closeModalDesktopEvent = new EventEmitter<void>;

  constructor(
    private visitDatesService: VisitDatesService,
    private isMobileService: IsMobileService,
    private modalMobileDeliveryDateService: ModalMobileDeliveryDateService,
    private modalDesktopDeliveryDateService: ModalDesktopDeliveryDateService,
    private selectDeliveryDateInfoService: SelectDeliveryDateInfoService,
  ) { }

  ngOnInit(): void {
    this.getAmbientVisitDates();
  }

  private getAmbientVisitDates(): void {
    this.subscriptions.add(
      this.visitDatesService.getVisitDatesByClient(),
    );
  }

  updateCurrentVisitDate(): void {
    if (!this.selectedAmbientVisitDate) {
      return;
    }
    if (this.visitDatesService.isSameDeliveryDate(this.selectedAmbientVisitDate)) {
      this.closeModal();
      return;
    }
    

    this.closeModal();
    this.visitDatesService.updateCurrentVisitDateBySelectedDate(
      this.selectedAmbientVisitDate
    );
  }

  closeModal(): void {
    this.isMobile
      ? this.modalMobileDeliveryDateService.closeModal()
      : this.closeModalDesktopEvent.emit();
  }

  updateSelectedDate(selectedAmbientVisitDate: VisitDate): void {
    this.selectedAmbientVisitDate = selectedAmbientVisitDate;
  }

  ngOnDestroy(): void {
    this.isMobile
      ? this.modalMobileDeliveryDateService.resetCanCloseWithShadow()
      : null;
    this.selectDeliveryDateInfoService.resetTexts();
    this.subscriptions?.unsubscribe();
  }
}
