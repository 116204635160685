<ng-container *ngIf="client$ | async as client">
  <radio-group [formControl]="control">
    <ng-container *ngIf="ambientSmartDeliveryVisitDates.length > 0">
      <div class="select-date-options-container smart-container">
        <div class="select-date-options-title smart-title">
          {{'NEW_ORDER.DELIVERY_PLAN.SMART_DELIVERY_TITLE' | translate}}
        </div>

        <div class="select-date-options-subtitle">
          {{ 'NEW_ORDER.DELIVERY_PLAN.SMART_DELIVERY_SUBTITLE' | translate }}
        </div>
        <div class="mb-16"></div>

        <div class="radio-container">
          <ng-container *ngFor="let date of ambientSmartDeliveryVisitDates; trackBy: trackByDates">
            <div class="radio-option">
              <ng-container *ngTemplateOutlet="datesTemplate; context: { date: date }"></ng-container>
            </div>
          </ng-container>
        </div>
      </div>      
    </ng-container>

    <ng-container *ngIf="ambientOnOffSmartVisitDates.length > 0">
      <div 
        class="select-date-options-container"
        [ngClass]="{
          'paddint-top-zero' : ambientSmartDeliveryVisitDates.length > 0
        }">

        <div class="radio-container">
          <ng-container *ngFor="let date of ambientOnOffSmartVisitDates; trackBy: trackByDates">
            <div class="radio-option">
              <ng-container *ngTemplateOutlet="datesTemplate; context: { date: date }"></ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    
    <ng-template #datesTemplate let-date="date">
      <div class="radio-option-info">
        <app-radio [value]="date">
          {{date.visitDate | weekdayDayMonthParser}}
        </app-radio>
    
        <app-label 
          *ngIf="date | canShowLabelAditionalSmartDate" 
          [text]="'NEW_ORDER.DELIVERY_PLAN.ADITIONAL_SD_DATE' | translate" 
          [class]="'success'">
        </app-label>
      </div>
    
      <div 
        *ngIf="
          date.offRoute && 
          date.quantityMinOffRoute && 
          showMinAmountByDates
        "
        class="radio-option-min-amount">
        {{ date.quantityMinOffRoute | boxes }}
      </div>
      
      <div 
        *ngIf="
          !date.offRoute && 
          date.amountMinRoute && 
          showMinAmountByDates
        "
        class="radio-option-min-amount"
        [ngClass]="{'green-text': showGreenTextMinAmount}">
        <svg 
          *ngIf="showGreenTextMinAmount"
          class="icon">
          <use xlink:href="./assets/icons/tabler-sprite.svg#tabler-truck" />
        </svg>

        {{
          'NEW_ORDER.DELIVERY_PLAN.MINIMUM_AMOUNT' | translate: {
            amount: date.amountMinRoute | currency
          } 
        }}
      </div>
    </ng-template>
  </radio-group>
</ng-container>

