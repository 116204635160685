import { createSelector } from '@ngrx/store';

import { Client } from 'src/app/core/models/client.model';
import { AppState } from 'src/app/core/state/app.state';

export const selectClientsState = (state: AppState) => state.client;
export const selectClientVisitDates = createSelector(
  selectClientsState,
  (state: Client) => state.data.visitDates,
);
export const SMART_DELIVERY = 'smartDelivery';