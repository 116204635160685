import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable} from 'rxjs';
import { EndpointsCodes } from '../enums/endpoints-codes.enum';
import { BERespModel } from '../models/backend/BE-response.model';
import { Store } from '@ngrx/store';
import { Client } from '../models/client.model';

@Injectable({
  providedIn: 'root'
})
export class NewslettersService {
  clientId: number;
  erpClientId: string;

  constructor(
    private apiService: ApiService,
    private store: Store<{ client: Client }>,
  ) { 
    this.store
      .select('client')
      .subscribe((client) => {
        this.erpClientId = client.erpClientId
        this.clientId = client.clientId
      });    
  }

  getNewsletterByClientId(email:string): Observable<BERespModel> {
    let url = `clients/${this.clientId}/newsletters`;
    if (email) {
      url += `?email=${email}`;
    }
    return this.apiService.get(
      url,
      EndpointsCodes.GET_NEWSLETTER_BY_CLIENT_ID,
       {
        targetApi: 'serverUrlPublic',
        showError: false
       },
    )    
  }

  setNewsletterStatus(newsLetterId: string, email: string, type: string): Observable<BERespModel> {
    return this.apiService.post(      
      `clients/${this.clientId}/newsletters/${newsLetterId}/status`,
      EndpointsCodes.POST_NEWSLETTER_SET_STATUS,
      {
        email,
        type        
       },
       {
        targetApi: 'serverUrlPublic',
        showError: false
       },
    )
  } 
}