import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '../../state/app.state';
import {
  updateMinBoxesUser,
  updateMinPurchase,
  updateMinPurchaseFrozen
} from '../../state/actions/cart.actions';

import { VisitDate } from '../../models/client.model';

@Injectable({
  providedIn: 'root'
})
export class MinAmountService {

  constructor(private store: Store<AppState>) { }

  setAmbientMinAmount(visitDate: VisitDate): void {
    if (visitDate.offRoute) {
      this.updateAmbientOffRouteMinAmount(visitDate.quantityMinOffRoute);
    } else {
      this.updateAmbientOnRouteMinAmount(visitDate.amountMinRoute);
    }
  }

  setFrozenMinAmount(visitDate: VisitDate): void {
    if (!visitDate.offRoute) {
      this.updateFrozenOnRouteMinAmount(visitDate.amountMinFrozen);
    }
  }

  updateAmbientOnRouteMinAmount(minAmount: number): void {
    this.store.dispatch(
      updateMinPurchase({ minPurchase: minAmount })
    );
  }

  updateAmbientOffRouteMinAmount(minAmount: number): void {
    this.store.dispatch(
      updateMinBoxesUser({ minBoxesUser: minAmount })
    );
  }

  updateFrozenOnRouteMinAmount(minAmount: number): void {
    this.store.dispatch(
      updateMinPurchaseFrozen({ minPurchaseFrozen: minAmount })
    );
  }
}
