import { Pipe, PipeTransform } from '@angular/core';
import { CountryCodes } from 'src/app/core/enums/country-codes.enum';
import { Client } from '../../core/models/client.model';
import { UserInfo } from '../../core/models/user-info.model';

@Pipe({
  name: 'notificationQuantityCalculation'
})
export class NotificationQuantityCalculationPipe implements PipeTransform {

  transform(value: number, client: Client, user: UserInfo): number {

    if (user.countryId === CountryCodes.PARAGUAY) {
      value = client?.data?.drafts[0]?.items.length;
      return value;
    }

    client?.data?.drafts[0]?.items.forEach((item) => {
      value += item.quantity;
    });

    return value;
  }

}
